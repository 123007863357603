import axios from "axios";

export const axiosInstance = axios.create({
    baseURL:'http://api.worldbank.org/v2/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

